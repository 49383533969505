<style lang="sass">.name {
  font-weight: 600;
}

.internal {
  background: linear-gradient(to right, red, lime, blue);
  background-clip: text;
  color: transparent;
}</style>

<span class={name[0] === "internal" ? "name internal" : "name"}>{name[1]}</span>

<script>
    export let name
</script>